<template>
    <div class="home" style="margin-left:30px; margin-top: 30px">
        <v-container fluid class="mt-4">
            <v-row>
                <v-col cols="12" sm="3">
                    <v-card color="#006400" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Total No. of Flights
                                            </v-list-item-subtitle>
                                            <v-icon color="white" x-large>flight</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <!-- <v-list-item-subtitle class="subtitle-1 white--text" style="font-size:19px"
                                              >742,824</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                              class="white--text"
                                              style="font-size: 11px"
                                              >CHANGE TO</v-list-item-subtitle
                                            > -->
                                            <v-list-item-subtitle class="white--text"
                                                                  style="font-size: 26px">3200</v-list-item-subtitle>

                                            <v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                            <span class="white--text">3211</span><br />
                                            <span class="white--text">(2.16%)</span>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card color="#FFA500" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Total No. of Incoming Flights
                                            </v-list-item-subtitle>
                                            <v-icon color="white" x-large>flight_land</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                              >914,175</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                              class="white--text"
                                              style="font-size: 11px"
                                              >CHANGE TO</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                              class="white--text mb-5"
                                              style="gont-size: 11px"
                                              >PREVIOUSE MONTH</v-list-item-subtitle
                                            > -->

                                            <v-list-item-subtitle class="white--text"
                                                                  style="font-size: 26px">1700</v-list-item-subtitle>

                                            <v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="red" class="mt-2 mr-2">fas fa-sort-down</v-icon>
                                            <span class="white--text">1697</span><br />
                                            <span class="white--text">(0.12%)</span>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card color="#077dfa" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Total No. of Outgoing Flights
                                            </v-list-item-subtitle>
                                            <v-icon color="white" x-large>flight_takeoff</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                              >415,721</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                              class="white--text"
                                              style="font-size: 11px"
                                              >CHANGE TO</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                              class="white--text mb-5"
                                              style="gont-size: 11px"
                                              >PREVIOUSE MONTH</v-list-item-subtitle
                                            > -->
                                            <v-list-item-subtitle class="white--text"
                                                                  style="font-size: 26px">1511</v-list-item-subtitle>

                                            <v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                            <span class="white--text">1514</span><br />
                                            <span class="white--text">(4.16%)</span>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card color="#8B0000" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Count of Flight Type
                                            </v-list-item-subtitle>
                                            <v-icon color="white" x-large>flight_class</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                              >274,762</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                              class="white--text"
                                              style="font-size: 11px"
                                              >CHANGE TO</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                              class="white--text mb-5"
                                              style="gont-size: 11px"
                                              >PREVIOUSE MONTH</v-list-item-subtitle
                                            > -->
                                            <v-list-item-subtitle class="white--text"
                                                                  style="font-size: 26px">8</v-list-item-subtitle>

                                            <v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                            <span class="white--text">10</span><br />
                                            <span class="white--text">(2.16%)</span>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                
                        <v-col
                                cols="4"
                                v-for="title in titles"
                                v-bind:key="title.link"
                                class="viewCard"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <router-link :to="title.link">
                                        <v-card
                                                :elevation="10"
                                                class="blue darken-1 text-center"
                                                height="100"
                                                width="100%"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                            class="white--text subtitle-1 mt-1 pa-4"
                                                    >{{title.name}}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="white--text header-6 mt-1">
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </router-link>
                                </template>
                            </v-tooltip>
                        </v-col>
                   

                <v-col cols="12" sm="6">
                    <v-card class="pa-5" elevation="5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            Performance of Airlines
                        </h1>
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="chartData"
                                        :options="chartOptions"
                                        style="height: 350px" />
                            </div>
                        </template>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-card class="pa-5" elevation="5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            Top 10 Booking Airlines
                        </h1>
                        <template>
                            <div id="chart" ml="10">
                                <apexchart type="pie"
                                           height="350"
                                           width="650"
                                           align="center"
                                           :options="chartsOptions"
                                           :series="series"></apexchart>
                            </div>
                        </template>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-card elevation="5" class="pa-5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            List of Airlines
                        </h1>
                        <template>
                            <v-carousel cycle
                                        dark
                                        height="550"
                                        hide-delimiter-background
                                        show-arrows-on-hover>
                                <v-carousel-item v-for="(slide, i) in slides" :key="i">
                                    <v-sheet color="white" height="80%" align="center" tile>
                                        <v-row class="fill-height" align="center" justify="center">
                                            <v-col class="ml-5" cols="12" sm="3">
                                                <v-card class="mt-5"
                                                        max-height="500"
                                                        max-width="400"
                                                        :elevation="24"
                                                        style="background:white"
                                                        >
                                                    <v-img height="200"
                                                           class="white--text align-end"
                                                           src="../../assets/BuddhaAirMain.jpg">
                                                        <v-card-title>Buddha Air</v-card-title>
                                                    </v-img>
                                                    <v-card-subtitle class="pb-0 black--text">
                                                        Tahachal Marg, Kathmandu 44600,
                                                        Nepal
                                                    </v-card-subtitle>
                                                    <v-card-text class="text--primary">
                                                        <div style="overflow-y: scroll; max-height: 180px">
                                                            Buddha Air, a household name in the Nepali
                                                            aviation sector has become the trademark of trust
                                                            and safety in 23 years of its service. Our journey
                                                            had humbly begun on October 11, 1997, with a
                                                            single aircraft. Today we fly to at least 15
                                                            national and international destinations a day with
                                                            our 14 state-of-art aircraft.Buddha Air, a household name in the Nepali
                                                            aviation sector has become the trademark of trust
                                                            and safety in 23 years of its service. Our journey
                                                            had humbly begun on October 11, 1997, with a
                                                            single aircraft. Today we fly to at least 15
                                                            national and international destinations a day with
                                                            our 14 state-of-art aircraft.
                                                        </div>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <button @click="$router.push('/Airport/Details')"
                                                                class="white--text cyan darken-2"
                                                                dark
                                                                style="padding: 5px; border-radius: 5px">
                                                            Details
                                                        </button>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-card class="mt-5"
                                                        max-height="500"
                                                        max-width="400"
                                                        :elevation="24"
                                                        style="background:white"
                                                        >
                                                    <v-img height="200"
                                                           class="white--text align-end"
                                                           src="../../assets/yeti.jpg">
                                                        <v-card-title>Yeti Airlines</v-card-title>
                                                    </v-img>
                                                    <v-card-subtitle class="pb-0 black--text">
                                                        Durbar Marg, Kathmandu 44600,
                                                        Nepal
                                                    </v-card-subtitle>
                                                    <v-card-text class="text--primary">
                                                        <div style="overflow-y: scroll; max-height: 180px">
                                                            Yeti Airlines Pvt. Ltd. started its first
                                                            commercial flight in September 1998 with a single
                                                            Canadian built DHC6-300 Twin Otter aircraft.
                                                            Within a decade it became the largest domestic
                                                            airline operator with a fleet of BAE Systems
                                                            Jetstream-41, DHC6-300 Twin Otter and the German
                                                            built Dornier Do 228 aircraft. Yeti Airlines has
                                                            added four ATR 72-500 into its fleets, two in
                                                            2017, one in 2018 and one in 2019 .
                                                            Yeti Airlines Pvt. Ltd. started its first
                                                            commercial flight in September 1998 with a single
                                                            Canadian built DHC6-300 Twin Otter aircraft.
                                                            Within a decade it became the largest domestic
                                                            airline operator with a fleet of BAE Systems
                                                            Jetstream-41, DHC6-300 Twin Otter and the German
                                                            built Dornier Do 228 aircraft. Yeti Airlines has
                                                            added four ATR 72-500 into its fleets, two in
                                                            2017, one in 2018 and one in 2019 .
                                                        </div>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <button @click="$router.push('/Airport/Details')"
                                                                class="white--text cyan darken-2"
                                                                dark
                                                                style="padding: 5px; border-radius: 5px">
                                                            Details
                                                        </button>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-card class="mt-5"
                                                        max-height="500"
                                                        max-width="400"
                                                        :elevation="24"
                                                        style="background:white"
                                                        >
                                                    <v-img height="200"
                                                           class="white--text align-end"
                                                           src="../../assets/na.jpg">
                                                        <v-card-title>Nepal Airlines</v-card-title>
                                                    </v-img>
                                                    <v-card-subtitle class="pb-0 black--text">Taragaon, Kathmandu 44601, Nepal</v-card-subtitle>
                                                    <v-card-text class="text--primary">
                                                        <div style="overflow-y: scroll; max-height: 180px">
                                                            Nepal Airlines Corporation (NAC) was incorporated
                                                            on 1 July 1958 through enactment of Nepal Airlines
                                                            Corporation Act. 2019 with the following main
                                                            objective to provide air transport service to any
                                                            person, agency or organization who need such
                                                            service for transportation of men or materials
                                                            from one airport to another either within or
                                                            outside the country.Nepal Airlines Corporation (NAC) was incorporated
                                                            on 1 July 1958 through enactment of Nepal Airlines
                                                            Corporation Act. 2019 with the following main
                                                            objective to provide air transport service to any
                                                            person, agency or organization who need such
                                                            service for transportation of men or materials
                                                            from one airport to another either within or
                                                            outside the country.
                                                        </div>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <button @click="$router.push('/Airport/Details')"
                                                                class="white--text cyan darken-2"
                                                                dark
                                                                style="padding: 5px; border-radius: 5px">
                                                            Details
                                                        </button>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-carousel-item>
                            </v-carousel>
                        </template>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-card color="#232620">
                        <v-app-bar color="rgba(0,0,0,0)" flat>
                            <v-toolbar-title class="white--text title">Domestic Passenger</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-dialog max-width="1000px" v-model="dialogCreate">
                                <!--<template v-slot:activator="{ on }">
                                    <v-chip :success="success"
                                           class="black--text clickbtn mb-2 float-right"
                                           color="white"
                                           dark
                                           v-on="on">
                                        Add Domestic Passenger
                                    </v-chip>
                                </template>-->
                                <createDivisionForm :success="success" @formResponse="onResponse" />
                            </v-dialog>
                        </v-app-bar>
                        <v-data-table :headers="headers" :items="desserts" class="dattab">
                            <template v-slot:item.glutenfree="{ item }">
                                <v-simple-checkbox v-model="item.glutenfree"
                                                   color="cyan"></v-simple-checkbox>
                            </template>
                            <template v-slot:item.actions="">
                                <v-icon small> {{ svgpencil }}</v-icon>
                                <v-icon small> {{ svgdelete }}</v-icon>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-card class="pa-5" elevation="5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            Monthly Performance of Airlines
                        </h1>
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="monthlyData"
                                        :options="chartOptions"
                                        style="height: 350px" />
                            </div>
                        </template>
                    </v-card>

                </v-col>

            </v-row>
        </v-container>
    </div>
</template>

<script>
    import { mdiPencil, mdiDelete } from "@mdi/js";
    import axios from "axios";
    // import createDivisionForm from "../DomesticPassenger/create.vue";

    export default {
        name: "Home",

        components: {
            // createDivisionForm
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {

                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        data() {
            return {
                titles: [
                    //{
                    //    name: "Generate Report",
                    //    link: "/report"
                    //},
                    {
                        name: "Profile",
                        link: "/branch"
                    },
                    {
                        name: "DomesticPassenger",
                        link: "/Airport/DomesticPassenger"
                    }
                ],
                branchInformation: [],
                roomCount: "",

                dialogCreate: false,
                svgpencil: mdiPencil,
                svgdelete: mdiDelete,
                slides: ["", "", "", ""],
                chartData: [
                    ["Flights", "Incoming", "Outgoing"],
                    ["Nepal Airlines", 15000, 12000],
                    ["Buddha Air", 14230, 12000],
                    ["Yeti Airlines", 13660, 15000],
                    ["Guna Airlines", 7030, 9020],
                    ["Himalaya Airlines", 12025, 11020],
                    ["Saurya Airlines", 3040, 3504],
                    ["Shree Airlines", 2100, 1420],
                    ["Sita Air", 10000, 12300],
                ],
                monthlyData: [
                    ["Month", "Passenger"],
                    ["Jan", 2000],
                    ["Feb", 2170],
                    ["Mar", 2060],
                    ["Apr", 3030],
                    ["May", 40250],
                    ["Jun", 40400],
                    ["Jul", 50000],
                    ["Aug", 58000],
                    ["Sep", 68000],
                    ["Oct", 7000],
                    ["Nov", 58000],
                    ["Dec", 68000],
                ],
                chartOptions: {
                    chart: {
                        title: "Company Performance",
                        subtitle: "Sales, Expenses, and Profit: 2014-2020",
                    },
                },
                series: [56090, 44961, 30200, 44336, 20350, 56210, 44219, 30212, 44320, 20120,],
                series1: [200, 250, 210, 190, 230, 240, 120, 220, 160, 150],
                chartsOptions: {
                    chart: {
                        width: 800,
                        type: "donut",
                    },
                    labels: [
                        "Buddha Air",
                        "Nepal Airlines",
                        "Yeti Airlines",
                        "Guna Airlines",
                        "Himalaya Airlines",
                        "Saurya Airlines",
                        "Shree Airlines",
                        "Sita Air",
                        "Tara Air",
                        "Summit Airlines"
                    ],
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 500,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
                desserts: [
                    {
                        sno: 1,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 60,
                        departureTime: "08:30 A.M",
                        arrivalTime: "10:00 A.M"
                    },
                    {
                        sno: 2,
                        airportName: "Yeti Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 40,
                        departureTime: "02:15 P.M",
                        arrivalTime: "3:45 P.M"
                    },
                    {
                        sno: 3,
                        airportName: "Guna Airlines",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 20,
                        departureTime: "04:45 P.M",
                        arrivalTime: "06:25 P.M"
                    },
                    {
                        sno: 4,
                        airportName: "Saurya Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 15,
                        departureTime: "06:25 P.M",
                        arrivalTime: "08:05 P.M"
                    },
                    {
                        sno: 5,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 20,
                        departureTime: "07:00 P.M",
                        arrivalTime: "08:30 P.M"
                    },
                ],
                headers: [
                    { text: "S.No", value: "sno" },
                    {
                        text: "Airport Name",
                        align: "start",
                        sortable: false,
                        value: "airportName",
                    },
                    { text: "Arrival Departure", value: "arrivalDeparture" },
                    { text: "Number of Person", value: "numberOfPerson" },
                    { text: "Departure Time", value: "departureTime" },
                    { text: "Arrival Time", value: "arrivalTime" },
                    { text: "Actions", value: "actions", sortable: false },
                ],
                files: [
                    {
                        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                        action: "Open",
                        headline: "Brunch this weekend?",
                        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
                        title: "<strong>Ali Connors</strong>",
                    },
                    {
                        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
                        action: "Open",
                        headline: "Brunch this weekend?",
                        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
                        title: "<strong>Ali Connors</strong>",
                    },
                    {
                        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
                        action: "Open",
                        headline: "Brunch this weekend?",
                        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
                        title: "<strong>Ali Connors</strong>",
                    },
                    {
                        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
                        action: "Open",
                        headline: "Brunch this weekend?",
                        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
                        title: "<strong>Ali Connors</strong>",
                    },
                ],
            };
        },
        mounted() {
            this.formData()
        },
        methods: {
            async formData() {
                const availableCount = await axios.get('Hotel/RoomStatusCountAsync', {
                    params: {
                        ShowCount: true
                    }
                })
                this.roomCount = availableCount.data
            },
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.serviceID = props.id;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Airport/DeleteDomesticPassengerByIDAsync/" + props.id;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                };
                axios.post("Airport/GetDomesticPassengerDetailListAsync", param).then(response => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                })
                    .catch(err => {
                        console.log(err)
                    });

            }
        }
    };
</script>
<style scoped>
    .datatab {
        background: #232620;
    }
</style>
